import React from "react";
import Workspace from "../Workspace/Workspace";
import Menu from "../Menu/Menu";
import AuthModal from "../ModalUser/AuthModal";
import "./MainPageStyles.css"
import { useSelector } from "react-redux";

const MainPage = () => {
  const { openBurger } = useSelector(state => state.userSlice);

  return (
    <div className={`main-page ${openBurger ? 'mobile-menu-open' : ''}`}>
      <AuthModal></AuthModal>
      <div className={`question-list ${openBurger ? 'hidden' : ''}`}>
        <Menu/>
      </div>
      <div className="work-area">
        <Workspace />
      </div>
    </div>
  );
};

export default MainPage;
