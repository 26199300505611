import { createSlice } from "@reduxjs/toolkit"
import { tasks } from "../../components/TasksList/tasks"
const initialState = {
  currentTaskNumber: -1,
  currentTask: {}
}

export const taskSlice = createSlice({
  name: 'taskData',
  initialState,
  reducers: {
    setCurrentTask(state, action) {
      const { taskNumber } = action.payload
      state.currentTaskNumber = taskNumber;
      state.currentTask = tasks[taskNumber];
    },
  }
})

export default taskSlice.reducer;
