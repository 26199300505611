import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  authUser: {
    username: "",
    token: localStorage.getItem('devToolsTrainingAppJWT')
  },
  showModal: false,
  language: localStorage.getItem('i18nextLng') || "en",
  openBurger: false
}

export const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setLoginUser(state, action) {
      const { token } = action.payload
      localStorage.setItem('devToolsTrainingAppJWT', token)
      state.authUser = action.payload;
    },
    setShowModal(state) {
      return { ...state, showModal: !state.showModal };
    },
    setLogoutUser(state) {
      localStorage.removeItem('devToolsTrainingAppJWT')
      return { ...state, authUser: { username: "", token: "" } };
    },
    setLanguage(state, action) {
      state.language = action.payload
      localStorage.setItem('i18nextLng', action.payload)
    },
    setBurger(state) {
      state.openBurger = !state.openBurger
    }
  }
})

export default userSlice.reducer;
