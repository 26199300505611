import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "../../redux/hooks/dispatch";
import TasksList from "../TasksList/TasksList";
import { userSlice } from "../../redux/reducers/userSlice";
import BurgerButton from '../Buttons/BurgerButton';
import logo from "../../images/main-logo.svg"
import "./Menu.css"

const Menu = () => {
  const { setShowModal, setLogoutUser } = userSlice.actions
  const dispatch = useAppDispatch();
  const currentUserToken = localStorage.getItem('devToolsTrainingAppJWT') ? localStorage.getItem('devToolsTrainingAppJWT') : ""
  const { authUser } = useSelector(state => state.userSlice);
  const { token } = authUser
  const isLogin = currentUserToken !== "" || token !== ""
  const { t } = useTranslation()

  return (
    <div className="menu">
      <div className="block-logo">
        <BurgerButton/>
        <img src={logo} alt="logo" />
        {
          isLogin ? 
            <div style={{ display: "flex", flexDirection: "column"}}>
              <button className="menu-button" onClick={() => {
                dispatch(setLogoutUser())
              }}>
                {t("menu.logout")}
              </button>
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column"}}>
              <button 
                className="menu-button"
                onClick={() => dispatch(setShowModal())}>
                {t("menu.login")}
              </button>
              <button
                className="menu-button"
                onClick={() => console.log("тут будет ссылка на телегу")}
              >
                Telegram
              </button>
            </div>
        }

      </div>
      <div className="tasks-block">
        <TasksList></TasksList>
      </div>
    </div>
  );
};

export default Menu;
