import React from "react";
import { useAppDispatch } from "../../redux/hooks/dispatch";
import { userSlice } from "../../redux/reducers/userSlice";
import { GiHamburgerMenu } from "react-icons/gi";

const BurgerButton = () => {
  const dispatch = useAppDispatch();
  const { setBurger } = userSlice.actions

  return (
    <>
      {
        window.innerWidth < 767 &&
        <div style={{ cursor: "pointer" }} onClick={() => dispatch(setBurger())}>
          <GiHamburgerMenu size={28}/>
        </div>
      }
    </>
  );
};

export default BurgerButton;
