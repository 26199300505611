export const tasks = [
  {
    "en": {
      taskName: <span>Request method</span>,
      description: <span>When the button Send is clicked, a <span className="green">POST</span> request will be sent to the backend</span>,
      correctAnswer: <span>The request nas been sent</span>,
      haveMistake: <span>Correct request method: </span>,
    },
    "ru": {
      taskName: <span>Метод запроса</span>,
      description: <span>При нажатии на кнопку Отправить произойдет HTTP запрос методом <span className="green">POST</span> на бэкенд</span>,
      correctAnswer: <span>Запрос с указанным методом отправляется корректно</span>,
      haveMistake: <span>Это не так. Правильный метод запроса: </span>,

    },
    id: 0,
    route: "request_method",
    requestOptions: [ "POST", "GET" ],
    correctOption: "POST",
    isLocked: false
  },
  {
    "en": {
      taskName: <span>Response status</span>,
      description: <span>When you click the send button, a request is sent to the backend. The response should have a response status of <span className="blue">202</span></span>,
      correctAnswer: <span>Response status is correct</span>,
      haveMistake: <span>Response status: </span>
    },
    "ru": {
      taskName: <span>Статус ответа</span>,
      description: <span>При нажатии на кнопку отправляется запрос на бэкенд. Ответ должен иметь статус <span className="blue">202</span></span>,
      correctAnswer: <span>Статус ответа корректный</span>,
      haveMistake: <span>Статус ответа: </span>

    },
    id: 1,
    route: "response_status",
    requestOptions: [ 202, 400 ],
    correctOption: 202,
    isLocked: false
  },
  {
    "en": {
      taskName: <span>Content of request body</span>,
      description: <span>When you click the button, a request is sent to the backend. <span className="blue">JSON</span> in the request body looks like:</span>,
      correctAnswer: <span><span className="blue">JSON</span> is transmitted correctly to the body</span>,
      haveMistake: <span>Request body is empty</span>

    },
    "ru": {
      taskName: <span>Содержимое тела запроса</span>,
      description: <span>При нажатии на кнопку Отправить отправляется запрос на бэкенд. <span className="blue">JSON</span> в теле запроса имеет вид:</span>,
      correctAnswer: <span><span className="blue">JSON</span> в теле передаётся корректно</span>,
      haveMistake: <span>Тело запроса пустое</span>
    },
    id: 2,
    route: "content_of_request_body",
    requestOptions: [ true, false],
    correctOption: true,
    isLocked: false,
    needInput: true,
    needJSON: true,
    dataJSON: (value) => { return {
      "name": value
    }}
  }, 
  {
    "en": {
      taskName: <span>Content of response body</span>,
      description: <span>When the button is clicked, a request is sent to the backend. In the response body you should receive <span className="blue">JSON</span>:</span>,
      correctAnswer: <span>The server sent the correct <span className="blue">JSON</span></span>,
      haveMistake: <span>The server did not send anything in response</span>
    },
    "ru": {
      taskName: <span>Содержимое тела ответа</span>,
      description: <span>При нажатии на кнопку Отправить пошлется запрос на бэкенд. В теле ответа должно вернуться <span className="blue">JSON</span>:</span>,
      correctAnswer: <span>Сервер прислал корректный <span className="blue">JSON</span></span>,
      haveMistake: <span>Сервер не прислал ничего в ответ</span>
    },
    id: 3,
    route: "content_of_response_body",
    requestOptions: [true, false],
    correctOption: true,
    isLocked: false,
    needJSON: true,
    dataJSON: () => {
      return {
        name: "content_of_response_body"
      }
    }
  }, 
  {
    "en": {
      taskName: <span>Cookie</span>,
      description: <span>When you click on the button the devtools_session_id cookie is set in the browser with a lifetime of <span className="green">1 year (365 days)</span></span>,
      correctAnswer: <span>Cookie <span className="blue">devtools_session_id</span> was set correctly</span>,
      haveMistake: <span>The <span className="blue">devtools_session_id</span> cookie was set to</span>,
    },
    "ru": {
      taskName: <span>Cookie</span>,
      description: <span>При нажатии на кнопку в браузере устанавливается cookie с названием devtools_session_id и сроком жизни <span className="green">1 год (365 дней)</span></span>,
      correctAnswer: <span>Cookie <span className="blue">devtools_session_id</span> была установлена корректно</span>,
      haveMistake: <span>Сookie <span className="blue">devtools_session_id</span> была установлена на</span>,
    },
    id: 4,
    route: "cookie",
    requestOptions: [90, 180, 365],
    correctOption: 365,
    isLocked: true
  },
  {
    "en": {
      taskName: <span>Request headers</span>,
      description: <span>When the button is clicked, a request is sent to the backend.The request contains the <span className="green">X-User-Id</span> header</span> ,
      correctAnswer: <span>The <span className="green">X-User-Id</span> header is sent correctly in the request</span>,
      haveMistake: <span>The request does not contain the X-User-Id header</span>
    },
    "ru": {
      taskName: <span>Заголовки запроса</span>,
      description: <span>При нажатии на кнопку отправляется запрос на бэкенд. Запрос содержит заголовок <span className="green">X-User-Id</span></span>,
      correctAnswer: < span > Заголовок < span className="green" > X - User - Id</span> передаётся в запросе корректно</span>,
      haveMistake: <span>Запрос не содержит заголовка <span className="green">X-User-Id</span></span>    
    },
    id: 5,
    route: "request_headers",
    requestOptions: [true, false],
    correctOption: true,
    isLocked: true
  },
  {
    "en": {
      taskName: <span>Response headers</span>,
      description: <span>When the button is clicked, a request is sent to the backend. The response contains the <span className="green">X-Request-Id</span> header</span>,
      correctAnswer: <span>Header <span className="green">X-Request-Id</span> is sent in the server response</span>,
      haveMistake: <span>The response does not contain the <span className="green">X-Request-Id</span> header. Instead, the header is sent in the request</span>
    },
    "ru": {
      taskName: <span>Заголовки ответа</span>,
      description: <span>При нажатии на кнопку отправляется запрос на бэкенд. Ответ содержит заголовок <span className="green">X-Request-Id</span></span>,
      correctAnswer: <span>Заголовок  передаётся в ответе сервера</span>,
      haveMistake: <span>Ответ не содержит заголовка <span className="green">X-Request-Id</span>. Вместо этого заголовок передаётся в запросе</span>
    },
    id: 6,
    route: "response_headers",
    requestOptions: [true, false],
    correctOption: true,
    correctAnswer: "correctAnswer",
    isLocked: true
  },
  {
    "en": {
      taskName: <span>Query parameters</span>,
      description: <span>When the button is clicked, a request is sent to the backend. The <span className="blue">section</span> parameter with the value <span className="purple">3</span> is passed to <span className="green">query</span></span>,
      correctAnswer: <span><span className="green">Query</span> parameters are passed correctly in the request</span>,
      haveMistake: <span>The request URL does not contain <span className="green">query</span> parameters. Instead, the data was transferred with request body</span>
},
    "ru": {
      taskName: <span>Query параметры</span>,
      description: <span>При нажатии на кнопку отправляется запрос на бэкенд. В <span className="green">query</span> передаётся параметр <span className="blue">section</span> со значением <span className="purple">3</span></span>,
      correctAnswer: <span><span className="green">Query</span> параметры передаются в запросе корректно</span>,
      haveMistake: <span>URL запроса не содержит <span className="green">query</span> параметров. Вместо этого данные были переданы в теле запроса</span>
},
    id: 7,
    route: "query_parameters",
    requestOptions: [true, false],
    correctOption: true,
    isLocked: true
  },
  {
    "en": {
      taskName: <span>Path parameters</span>,
      description: <span>When the button is clicked, a request is sent to the backend with <span className="green">path</span> parameter <span className="blue">section=1</span></span>,
      correctAnswer: <span><span className="green">Path</span> parameters are passed correctly in the request</span>,
      haveMistake: < span > Request URL does not contain <span className="green">path</span> parameters. Instead, it contains <span className="green">query</span>-parameters</span >
    },
    "ru": {
      taskName: <span>Path параметры</span>,
      description: <span>При нажатии на кнопку отправляется запрос на бэкенд с <span className="green">path</span> параметром <span className="blue">section=1</span></span>,
      correctAnswer: <span><span className="green">Path</span> параметры передаются в запросе корректно</span>,
      haveMistake: <span>URL запроса не содержит <span className="green">path</span> параметров. Вместо этого он содержит <span className="green">query</span>-параметры</span>
    },
    id: 8,
    route: "path_parameters",
    requestOptions: [true, false],
    correctOption: true,
    isLocked: true
  },
  {
    "en": {
      taskName: <span>Local storage</span>,
      description: <span>When you click the button, the value of the “name” field is saved in the browser\’s local storage with the <span className="green">name</span> key</span>,
      correctAnswer: <span>Value saved correctly</span>,
      haveMistake: <span>The value is stored with a different key —</span>,    },
    "ru": {
      taskName: <span>Local storage</span>,
      description: <span>При нажатии на кнопку значение поля “name” сохраняется в local storage браузера с ключом <span className="green">name</span></span>,
      correctAnswer: <span>Значение корректно сохранено</span>,
      haveMistake: <span>Значение сохранено с другим ключом —</span>
    },
    id: 9,
    needInput: true,
    requestOptions: ["name", "address"],
    correctOption: "name",
    route: "local_storage",
    isLocked: true
  },
  {
    "en": {
      taskName: <span>Session storage</span>,
      description: <span>When the button is clicked, the value of the “name” field is saved in the browser’s session storage with the key <span className="green">name</span></span>,
      correctAnswer: <span>Value saved correctly</span>,
      haveMistake: <span>Field value not saved</span>
    },
    "ru": {
      taskName: <span>Session storage</span>,
      description: <span>При нажатии на кнопку значение поля “name” сохраняется в session storage браузера с ключом <span className="green">name</span></span>,
      correctAnswer: <span>Значение корректно сохранено</span>,
      haveMistake: <span>Значение поля не сохранено</span>,    
    },
    id: 10,
    needInput: true,
    requestOptions: ["name", ""],
    correctOption: "name",
    route: "session_storage",
    isLocked: true
  },
  {
    "en": {
      taskName: <span>Cache</span>,
      description: <span>On the first load or hard refresh of the page, the image is loaded from the server, and when updated, from the cache. This is a self-testing task, you do not need to select the correct answer</span>,
      correctAnswer: "",
      helpDescription: 
        <div>
          <div>You can see where the image is loaded from in the “network” tab. Look for the status of the response to the image request.</div>
          <div>Keyboard shortcuts for hard page refresh:</div>
          <div>For Windows - <span className="green">ctrl shift r</span></div>
          <div>For MacOS - <span className="blue">cmd shift r</span></div>
        </div>
    },
    "ru": {
      taskName: <span>Кэш</span>,
      description: <span>При первой загрузке или hard reload страницы картинка загружается с сервера, а при обновлении — из кэша. Это задание без проверки</span>,
      correctAnswer: "",
      helpDescription:
        <div>
          <div>Откуда загружается картинка можно посмотреть во вкладке «network». Ищи статус ответа на запрос картинки.</div>
          <div>Сочетания клавиш для жёсткого обновления страницы:</div>
          <div>Для Windows — <span className="green">ctrl shift r</span></div>
          <div>Для MacOS — <span className="blue">cmd shift r</span></div>
        </div>
    },
    id: 11,
    route: "cache",
    requestOptions: [1, 2, 3],
    correctOption: 1,
    isLocked: true,
    needHelp: true,
    customForm: true

  },
  // {
  //   "en": {
  //     taskName: "Mobile layout",
  //     description: "In vertical orientation, the mobile layout matches the layout in Figma:",
  //     correctAnswer: ""
  //   },
  //   "ru": {
  //     taskName: "Мобильная вёрстка",
  //     description: "При вертикальной ориентации мобильная вёрстка соответствует макету в Figma:",
  //     correctAnswer: ""
  //   },
  //   id: 11,
  //   route: "mobile_layout",
  //   requestOptions: [1, 2, 3],
  //   correctOption: 1,
  //   isLocked: true
  // },
  {
    "en": {
      taskName: <span>CSS color</span>,
      description: <span>The button below has color <span className="blue">#f28338</span></span>,
      correctAnswer: <span>Button color is set correctly</span>,
      haveMistake: <span>Button color — </span>,
},
    "ru": {
      taskName: <span>CSS цвет</span>,
      description: <span>У кнопки ниже цвет <span className="blue">#f28338</span></span>,
      correctAnswer: <span>Цвет кнопки задан корректно</span>,
      haveMistake: <span>Цвет кнопки — </span>,
    },
    id: 12,
    route: "css_color",
    requestOptions: ["#f28338", "#f27394", "#f29849"],
    correctOption: "#f28338",
    isLocked: true,
    customForm: true
  },
  {
    "en": {
      taskName: <span>CSS font</span>,
      description: <span>The text below has a <span className="blue">Courier New</span> font</span>,
      correctAnswer: <span>Font is set correctly</span>,
      haveMistake: <span>Font —</span>,
    },
    "ru": {
      taskName: <span>CSS шрифт</span>,
      description: <span>У надписи ниже шрифт <span className="blue">Courier New</span></span>,
      correctAnswer: <span>Шрифт задан корректно</span>,
      haveMistake: <span>Шрифт —</span>, 
    },
    id: 13,
    route: "css_font",
    // requestOptions: [
    //   { fontFamily: "'Courier New', Courier, monospace", fontSize: "20px" },
    //   { fontFamily: "Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif", fontSize: "24px" },
    //   { fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif", fontSize: "28px" }
    // ],
    requestOptions: [ "'Courier New', Courier, monospace", "Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif", "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif"
    ],
    correctOption: "'Courier New', Courier, monospace",
    isLocked: true,
    customForm: true,
    text: {
      en: "QA engineer",
      ru: "QA инженер",
    }
  },
  // {
  //   "en": {
  //     taskName: "Field Validation",
  //     description: "When submitting a form, a check is made that the “Email” field corresponds to the email address format (the presence of “@”, etc.). The check occurs on the client and backend. The answer in the task does not change when the page is reset/reloaded",      correctAnswer: ""
  //   },
  //   "ru": {
  //     taskName: "Валидация поля",
  //     description: "При отправке формы происходит проверка, что поле «Email» соответствует формату адреса электронной почты (наличие «@» и др). Проверка происходит на клиенте и бэкенде. Ответ в задании не меняется при сбросе/перезагрузке странцы",
  //     correctAnswer: ""
  //   },
  //   id: 14,
  //   route: "field_validation",
  //   requestOptions: [1, 2, 3],
  //   correctOption: 1,
  //   isLocked: true
  // },
//   {
//     id: 15,
//     taskName: "Какой-то вопрос 15",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 16,
//     taskName: "Какой-то вопрос 16",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 17,
//     taskName: "Какой-то вопрос 17",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 18,
//     taskName: "Какой-то вопрос 18",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 19,
//     taskName: "Какой-то вопрос 19",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 20,
//     taskName: "Какой-то вопрос 20",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 21,
//     taskName: "Какой-то вопрос 21",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 22,
//     taskName: "Какой-то вопрос 22",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 23,
//     taskName: "Какой-то вопрос 23",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
//   {
//     id: 24,
//     taskName: "Какой-то вопрос 24",
//     wrongAnswer: "wrongAnswer",
//     description: "",
//     correctAnswer: "correctAnswer",
//     isLocked: true
//   },
]
