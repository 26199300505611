import React, { useState, useEffect } from "react";
import "./TaskStyle.css"
import { allRequests } from "../../utils/utils";
import { IoIosRefresh, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { FaRegLightbulb } from "react-icons/fa";
import { useTranslation } from 'react-i18next'
import { taskSlice } from "../../redux/reducers/taskSlice";
import { useAppDispatch } from "../../redux/hooks/dispatch";
import { deleteAllCookies } from "../../utils/utils";
import LanguageButton from "../Buttons/LanguageButton";
import BurgerButton from "../Buttons/BurgerButton";
import OverlayWithPopup from "../Buttons/OverlayWithPopup";

const Task = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const imageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgVABd80gfV3cIvIuwvLy_yw8Km0hk6ZMMkD7FM8XN-Q&s";

  const [ isSendForm, setIsSendForm ] = useState(false)
  const [ checkAnswer, setСheckAnswer ] = useState(false)
  const [ correctAnswer, setCorrectAnswer ] = useState("")
  const [ currentAnswer, setCurrentAnswer ] = useState("")

  const [ currentInputRadioValue, setCurrentInputRadioValue ] = useState(null);
  const [ correctInputRadioValue, setCorrectInputRadioValue ] = useState(null);

  const [ inputValue, setInputValue ] = useState('');
  const [ isCorrectAnswer, setIsCorrectAnswer ] = useState(false)
  const [ needHelpButton, setNeedHelpButton ] = useState(false)
  const [ loadingProgress, setLoadingProgress ] = useState(0);
  const [ colorOfResult, setColorOfResult ] = useState({});
  const [target, setTarget] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const { currentTask, currentTaskNumber } = useSelector(state => state.taskSlice);
  const { language } = useSelector(state => state.userSlice);

  const { setCurrentTask } = taskSlice.actions
  
  const updateTask = () => {
    setIsSendForm(false)
    setСheckAnswer(false)
    setCorrectAnswer("")
    setCurrentAnswer("")
    setCurrentInputRadioValue(null)
    setCorrectInputRadioValue(null)
    setIsCorrectAnswer(false)
    setInputValue("")
    setNeedHelpButton(false)
    deleteAllCookies()
    if (currentTask?.customForm) {
      const randomIndex = Math.floor(Math.random() * currentTask.requestOptions?.length);
      setCorrectAnswer(currentTask.correctOption);
      const currentOption = currentTask.requestOptions[randomIndex]
      allRequests({
        taskNumber: currentTask.id,
        options: currentOption,
        setResult: setCurrentAnswer,
        inputValue: inputValue
      })
      setCorrectInputRadioValue(currentTask.correctOption === currentOption ? "1" : "0")
    }
  }

  useEffect(() => {
    updateTask()
  }, [currentTaskNumber])

  const sendForm = () => {
    setIsSendForm(true)
    const randomIndex = Math.floor(Math.random() * currentTask.requestOptions?.length);
    const currentOption = currentTask.requestOptions[randomIndex]
    setCorrectAnswer(currentTask.correctOption);
    allRequests({
      taskNumber: currentTask.id,
      options: currentOption,
      setResult: setCurrentAnswer,
      inputValue: inputValue
    })
    setCorrectInputRadioValue(currentTask.correctOption === currentOption ? "1" : "0")
  }

  const handleAnswer = (e) => {
    if (!checkAnswer) {
      setCurrentInputRadioValue(e.target.value);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCheckResult = () => {
    setIsCorrectAnswer(correctInputRadioValue === currentInputRadioValue)
    getColor(correctInputRadioValue, currentInputRadioValue)
    setСheckAnswer(true)
  };

  const handleNextTask = (e) => {
    if (currentTaskNumber !== 3) {
      dispatch(setCurrentTask({ taskNumber: currentTaskNumber + 1 }))
    } else {
      handleShowPopup(e);
    }
  }

  useEffect(() => {
    const loadImage = async () => {
      const image = new Image();
      image.src = imageUrl;

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js')
          .then((registration) => {
            return registration
          })
          .catch((error) => {
            console.error('Ошибка регистрации Service Worker:', error);
          });
      }

      for (let i = 0; i <= 100; i++) {
        await new Promise(resolve => setTimeout(resolve, 50));
          setLoadingProgress(i);
      }

      setLoadingProgress(100);
    };
    loadImage();
  }, []);

  const getColor = (correct, current) => {
    correct === "0" ? (current === "0" ? setColorOfResult({ "0": "green", "1": "black" }) : setColorOfResult({ "0": "green", "1": "red" })) : (current === "0" ? setColorOfResult({ "0": "red", "1": "green" }) : setColorOfResult({ "0": "black", "1": "green" }))
  }

  const handleShowPopup = (event) => {
    setShowPopup(!showPopup);
    setTarget(event.target);
  };

  return (
    <>
      <div className="header-task">
        <BurgerButton />
        <span style={{ margin: "5px" }}>{currentTask[language]?.taskName}</span>
        <LanguageButton/>
      </div>
      <div style={{ color: "dimgrey", margin: "5px" }}>{t("workspace.task")}</div>
      <div style={{ margin: "5px" }}>{currentTask[language]?.description}</div>
      {
        currentTask.needJSON &&
        <pre className="data-json">
            {JSON.stringify(currentTask.dataJSON(inputValue), null, 2)}
        </pre>
      }
      {
        currentTask.needHelp &&
        <>
          {
            needHelpButton ?
            <>
              <div style={{ color: "dimgrey", margin: "5px" }}>{t("workspace.help")}</div>
              <div className="need-help-block">
                {currentTask[language].helpDescription }
              </div>
            </>
            :
            <div 
              className="need-help-button"
              onClick={() => setNeedHelpButton(true)}
            >
              <FaRegLightbulb size={14}/>
              {t("workspace.help")}
            </div>
          }
        </>
      }
      {
        !currentTask.customForm &&
        <div className="send-form">
          <div style={{ margin: "5px" }}>{t("workspace.form")}</div>
          <div style={{ textAlign: "center", margin: "0px" }}>
            {
              currentTask.needInput ?
              <div className="form-with-input">
                <input
                  className="input-in-form"
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder={t("workspace.input")}
                />
                <button
                  onClick={sendForm}
                  className="send-form-button"
                  disabled={isSendForm}
                  style={{ width: "calc(30% - 10px)", backgroundColor: isSendForm? "grey" : "black" }}
                >
                  {t("workspace.send")}
                </button>
              </div>
              :
              <button
                onClick={sendForm}
                className="send-form-button"
                disabled={isSendForm}
                style={{ backgroundColor: isSendForm? "grey" : "black" }}
              >
                {t("workspace.send")}
              </button>
            }
            {
              isSendForm &&
              <span className="send-form-badge">{t("workspace.requestSendSuccessfully")}</span>
            }
          </div>
        </div>
      }
      {
        currentTask.customForm &&
        <>
        {
          currentTask.id === 12 &&
          <div className="send-form">
            <div style={{ margin: "5px" }}>{t("workspace.form")}</div>
            <div style={{ textAlign: "center", margin: "0px" }}>
              <button
                className="send-form-button"
                disabled={true}
                style={{
                  backgroundColor: currentAnswer
                }}
              >
                {t("workspace.send")}
              </button>
            </div>
          </div>
        }
        {
          currentTask.id === 13 &&
          <div className="send-form" style={{height: "50px"}}>
              <div style={{ textAlign: "center", margin: "0px", fontFamily: currentAnswer }}>
                {currentTask.text[language]}
            </div>
          </div>
        }
        {
          currentTask.id === 11 &&
            <div className="send-form" style={{ height: "50%", position: 'relative' }}>
              <p>Идет загрузка изображения: {loadingProgress}%</p>
                <div style={{ height: "80%", position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(to bottom, white, transparent)',
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="Изображение"
                      style={{
                        borderRadius: "10px",
                        display: 'block',
                        width: '100%',
                        height: `${loadingProgress}%`,
                        objectFit: 'cover',
                        objectPosition: `center top`,
                      }}
                    />
                  </div>
              </div>
            </div>
        }
        </>
      }
      {
          (isSendForm || (currentTask.customForm && currentTask.id !== 11)) &&
        <div>
          <div style={{ color: "dimgrey"}}>
            {t("workspace.testResult")}
          </div>
          <div>
            <label>
              <input
                type="radio"
                value="1"
                checked={currentInputRadioValue === "1"}
                onChange={handleAnswer}
              />
              <span className={
                !checkAnswer ? 
                "custom-radio-black" : `custom-radio-${colorOfResult["1"]}`
              }></span>
              <span style={{ 
              color: !checkAnswer ? "black" : colorOfResult["1"]
                }}
              >
                {t("workspace.result.right")}
              </span>
            </label>
            <span>
              {
                checkAnswer && currentInputRadioValue === "1" &&
                <>
                  {
                    isCorrectAnswer ?
                      <div className="result-block">
                        {currentTask[language]?.correctAnswer}
                      </div>
                      :
                      <div className="result-block">
                        <span>{currentTask[language]?.haveMistake} </span>
                        <span className="purple">{currentAnswer}</span>
                      </div>
                  }
                </>
              }
            </span>
          </div>
          <div>
            <label>
              <input
                type="radio"
                value="0"
                checked={currentInputRadioValue === "0"}
                onChange={handleAnswer}
              />
              <span className={
                !checkAnswer ? "custom-radio-black" : `custom-radio-${colorOfResult["0"]}`
              }></span>
              <span style={{
              color: !checkAnswer ? "black" : colorOfResult["0"]
              }}
              >
                {t("workspace.result.error")}
              </span>
            </label>
            {
              checkAnswer && currentInputRadioValue === "0" &&
              <>
                {
                  !isCorrectAnswer ?
                    <div className="result-block">
                      {currentTask[language]?.correctAnswer}
                    </div>
                    :
                    <div className="result-block">
                      <span>{currentTask[language]?.haveMistake} </span>
                      {
                        typeof(correctAnswer) !== "boolean" &&
                        <span className="purple">{currentAnswer}</span>
                      }
                    </div>
                }
              </>
            }
          </div>
          {
            !checkAnswer ?
            <>
              <button
                onClick={handleCheckResult}
                className="check-button"
                disabled={currentInputRadioValue === null}
              >
                {t("workspace.check")}
              </button>
            </>
            :
            <>
              <div className="double-buttons">
                <button
                  onClick={() => updateTask()}
                  className="white-button"
                  style={{marginRight: "5px"}}
                >
                  <IoIosRefresh size={14}/>
                  <span>{t("workspace.again")}</span>
                </button>
                <button
                  onClick={(e) => handleNextTask(e)}
                  className="black-button"
                  style={{ marginLeft: "5px" }}
                >
                  <span>{t("workspace.further")}</span>
                  <IoIosArrowForward/>
                </button>
              </div>
            </>
          }
        </div>
      }
      <OverlayWithPopup
        target={target}
        show={showPopup}
        setShowPopup={setShowPopup}
        placement={"left"}
      />
    </>
  );
};

export default Task;
