import React from "react";
import { useSelector } from "react-redux";
import HowDoesTheTrainerWork from "./HowDoesTheTrainerWork";
import Task from "./Task";
import "./TaskStyle.css"

const Workspace = () => {
  const { currentTaskNumber } = useSelector(state => state.taskSlice);

  return (
    <div className="block-with-task">
      {
        currentTaskNumber === -1 ?
          <HowDoesTheTrainerWork/>
          :
          <Task/>
      }
    </div>
  );
};

export default Workspace;
