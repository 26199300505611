import React from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { useAppDispatch } from '../../redux/hooks/dispatch';
import { userSlice } from '../../redux/reducers/userSlice';
import { useTranslation } from 'react-i18next'
import { IoCloseSharp } from "react-icons/io5";
import "./Buttons.css"

const OverlayWithPopup = ({ target, show, setShowPopup, placement}) => {
  const { t } = useTranslation()
  const { setShowModal } = userSlice.actions
  const dispatch = useAppDispatch();

  return (
    <Overlay
      show={show}
      target={target}
      placement={placement}
      containerPadding={20}
      style={{ overflow: 'hidden' }}
    >
      <Popover>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>
            {t("popup.header")}
          </h4>
          <div onClick={() => setShowPopup()} style={{cursor: "pointer"}}>
            <IoCloseSharp size={24} />
          </div>
        </div>
        <span>
          {t("popup.body")}
        </span>
        <div className="popover-buttons">
          <button className="popover-button" onClick={() => {
            dispatch(setShowModal())
            setShowPopup()
          }
          }>
            {t("popup.login")}
          </button>
          <button className="popover-button" onClick={() => window.open('https://t.me/tallinnlearning', '_blank')}>
           {t("popup.telegram")}
          </button>
        </div>
      </Popover>
    </Overlay>
  );
};

export default OverlayWithPopup;
