import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { taskSlice } from "../../redux/reducers/taskSlice";
import { useAppDispatch } from "../../redux/hooks/dispatch";
import LanguageButton from "../Buttons/LanguageButton";
import "./TaskStyle.css"
import BurgerButton from "../Buttons/BurgerButton";

const HowDoesTheTrainerWork = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { setCurrentTask } = taskSlice.actions
  const currentUserToken = localStorage.getItem('devToolsTrainingAppJWT') ? localStorage.getItem('devToolsTrainingAppJWT') : ""
  const { authUser } = useSelector(state => state.userSlice);
  const { token } = authUser
  const isLogin = currentUserToken !== "" || token !== ""

  return (
    <div style={{ margin: "5px" }}>
      <div className="header-task">
        <BurgerButton />

        <h2 style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.mainTitle")}</h2>
        <LanguageButton />
      </div>
      <h4 style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.title1")}</h4>
      <div style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.text1")}</div>
      <h4 style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.title2")}</h4>
      <div style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.text2")}</div>
      <h4 style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.title3")}</h4>
      <ul>
        <li style={{ margin: "0px" }}>{t("howDoesTheTrainerWork.text3_1.1")}</li>
        <li style={{ margin: "0px" }}>{t("howDoesTheTrainerWork.text3_1.2")}</li>
        <li style={{ margin: "0px" }}>{t("howDoesTheTrainerWork.text3_1.3")}</li>
        <li style={{ margin: "0px" }}>{t("howDoesTheTrainerWork.text3_1.4")}</li>
      </ul>
      <div style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.text3_2")}</div>
      <h4 style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.title4")}</h4>
      <div style={{ margin: "5px" }}>{t("howDoesTheTrainerWork.text4")}</div>
      <button
        onClick={() => dispatch(setCurrentTask({ taskNumber: 0 }))}
        className="send-form-button"
      >
        {
          isLogin ?
          <>
            {t("howDoesTheTrainerWork.startAuth")}
          </>
          :
          <>
            {t("howDoesTheTrainerWork.start")}
          </>
        }
      </button>
    </div>
  );
};

export default HowDoesTheTrainerWork;
