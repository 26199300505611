import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, InputGroup, Form } from 'react-bootstrap';
import { toaster } from 'evergreen-ui';
import { userSlice } from '../../redux/reducers/userSlice';
import { useAppDispatch } from '../../redux/hooks/dispatch';
import { login } from '../../utils/api';
import { useTranslation } from 'react-i18next'

import "./AuthModal.css"
function AuthModal({}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation()

  const dispatch = useAppDispatch();
  const { showModal } = useSelector(state => state.userSlice);
  const { setLoginUser, setShowModal } = userSlice.actions

  const handleClose = () => {
    dispatch(setShowModal())
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    login({ username, password, setLoginUser, dispatch })
    setUsername("");
    setPassword("");
  }
  const checkPass = (password) => {
    if (password.length === 0)
      return true;
    if (password.trim() === '')
      return false;
    else
      return true;
  }

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header style={{padding: "7px"}} closeButton>
        <Modal.Title>{t("authModal.modalHeader")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "7px" }}>
        <form onSubmit={(e) => onSubmit(e)}>
          <InputGroup size="sm" style={{ margin: "10px 0px 10px 0px" }}>
            <InputGroup.Text style={{ width: "86px" }} id="inputGroup-sizing-sm">{t("authModal.username")}</InputGroup.Text>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" style={{ margin: "10px 0px 10px 0px" }}>
            <InputGroup.Text style={{ width: "86px" }} id="inputGroup-sizing-sm">{t("authModal.password")}</InputGroup.Text>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </InputGroup>
          <div style={{ display: "flex", flexDirection: "row-reverse"}}>
            <Button size="sm" className="submit-button" type='submit' disabled={!checkPass(password)} onClick={handleClose}>
              {t("authModal.login")}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AuthModal;
