import { combineReducers, configureStore } from "@reduxjs/toolkit";
import taskSlice from "./reducers/taskSlice";
import userSlice from "./reducers/userSlice";

const rootReducer = combineReducers({
  taskSlice,
  userSlice
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }),
  })
}
