import { 
  requestMethod,
  responseStatus,
  contentOfRequestBody,
  contentOfResponseBody,
  setCookie,
  requestHeaders,
  responseHeaders,
  queryParameters,
  pathParameters,
  localStorageTask,
  sessionStorageTask,
  cache,
  // mobileLayout,
  cssColor,
  cssFont,
  // fieldValidation
} from "./api"

const MAIN_URL = "https://backend.tallinn-learning.ee"

const url = (option) => {
  return `${MAIN_URL}/${option}`
}

export function allRequests({taskNumber, options, setResult, inputValue}) {
  switch (taskNumber) {
    case 0:
      return requestMethod(options, setResult)
    case 1:
      return responseStatus(options, setResult)
    case 2:
      return contentOfRequestBody(options, setResult, inputValue)
    case 3:
      return contentOfResponseBody(options, setResult)
    case 4:
      return setCookie(options, setResult)
    case 5:
      return requestHeaders(options, setResult)
    case 6:
      return responseHeaders(options, setResult)
    case 7:
      return queryParameters(options, setResult)
    case 8:
      return pathParameters(options, setResult)
    case 9:
      return localStorageTask(options, setResult, inputValue)
    case 10:
      return sessionStorageTask(options, setResult, inputValue)
    case 11:
      // return mobileLayout(options, setResult)
      return cache(options, setResult)
    case 12:
      return cssColor(options, setResult)
    case 13:
      return cssFont(options, setResult)
    // case 14:
    //   return fieldValidation(options, setResult)
    default:
      break;
  }
}

export const getFetch = async (option, method, body, headers) => {
  return await fetch(
    url(option),
    {
      method: method,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: body
    }
  )
}

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
};

export const isTokenValid = () => {
  const token = localStorage.getItem('devToolsTrainingAppJWT');
  if (!token) {
    return false;
  }
  const { exp } = JSON.parse(atob(token.split('.')[1]));
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime < exp;
};
