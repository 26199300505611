import React, { useState } from "react";
import "./Buttons.css"
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "../../redux/hooks/dispatch";
import { userSlice } from "../../redux/reducers/userSlice";
import { IoGlobeOutline } from 'react-icons/io5';

const LanguageButton = () => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { setLanguage } = userSlice.actions

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (language) => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language))
    setDropdownOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
        <IoGlobeOutline size={24}/>
        {isDropdownOpen && (
          <svg
            style={{
              position: 'absolute',
              top: '50%',
              left: '-52%',
              transform: 'translateY(-50%)',
              fill: 'white',
            }}
            height="16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0 L8 8 L0 16 Z" />
          </svg>
        )}
      </div>
      {isDropdownOpen && (
        <div
          className="dropdown-for-lang"
        >
          <div style={{ borderBottom: "1px solid black" }} onClick={() => handleItemClick("ru")}>Русский</div>
          <div onClick={() => handleItemClick("en")}>English</div>
        </div>
      )}
    </div>
  );
};

export default LanguageButton;
