import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tasks } from "./tasks";
import { IoIosLock } from "react-icons/io";
import { useAppDispatch } from '../../redux/hooks/dispatch';
import { userSlice } from '../../redux/reducers/userSlice';
import { taskSlice } from '../../redux/reducers/taskSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import OverlayWithPopup from '../Buttons/OverlayWithPopup';
import "./TasksListStyles.css"

const TasksList = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const { authUser, language } = useSelector(state => state.userSlice);
  const { currentTask, currentTaskNumber } = useSelector(state => state.taskSlice);

  const { setBurger } = userSlice.actions
  const { setCurrentTask } = taskSlice.actions

  const [ showPopup, setShowPopup ] = useState(false);
  const [ target, setTarget ] = useState(null);
  const dispatch = useAppDispatch();
  const { token } = authUser
  const currentUserToken = localStorage.getItem('devToolsTrainingAppJWT') ? localStorage.getItem('devToolsTrainingAppJWT') : ""
  const isLogin = currentUserToken !== "" || token !== ""

  const handleShowPopup = (event) => {
    setShowPopup(!showPopup);
    setTarget(event.target);
  };

  useEffect(() => {
    if (currentTaskNumber !== -1) {
      navigate(`/tasks/${currentTask.route}`);
    }
  }, [currentTask?.route, currentTaskNumber, navigate])

  return (
    <div ref={ref} className="block-with-tasks">
      <div style={{ borderBottom: "1px solid black" }}>
        <div
          onClick={() => {
            dispatch(setCurrentTask({ taskNumber: -1 }));
            navigate("/");
            window.innerWidth < 767 && dispatch(setBurger())
          }}
          className={`task ${-1 === currentTaskNumber ? 'selected' : ''}`}
        >
          <span>{t("menu.howDoesTheTrainerWork")}</span>
        </div>

      </div>
      {
        tasks.map((q, id) => (
          <div
            key={id}
            onClick={(e) => {
              if (isLogin || (!q.isLocked && !isLogin)) {
                dispatch(setCurrentTask({ taskNumber: id }));
                window.innerWidth < 767 && dispatch(setBurger())
              } else {
                handleShowPopup(e);
              }
            }}
            className={`task ${q.id === currentTaskNumber ? 'selected' : ''}`}
            style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}
          >
            <span className="tasks-list-number">
              {q.id + 1}.
            </span>
            <span style={{ flex: '1' }}>
              {q[language]?.taskName}
            </span>
            {
              (isLogin || (!q.isLocked && !isLogin)) ? <></> : <span className="lock-icon"><IoIosLock /></span>
            }
          </div>
        ))
      }
      <OverlayWithPopup
        target={target}
        show={showPopup}
        setShowPopup={setShowPopup}
        placement={"right"}
      />
    </div>
  );
};

export default TasksList;
