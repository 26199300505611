import { getFetch } from "./utils";
import { toaster } from "evergreen-ui";

export const login = async ({ username, password, setLoginUser, dispatch }) => {
  await getFetch('login/student', 'POST', JSON.stringify({
    username,
    password,
  }))
    .then(res => {
      return res.ok ? res.text() : res
    }
    )
    .then(data => {
      if (typeof data === "string") {
        dispatch(setLoginUser({ username: username, token: data }))
        toaster.success('Пользователь успешно авторизован', { duration: 2 });
      } else {
        toaster.danger('Неправильное имя пользователя или пароль', { duration: 2 });
      }
    })
    .catch((err) => { 
      toaster.danger('Неправильное имя пользователя или пароль', { duration: 2 });
      console.error(err)
    })
}

  async function sendRequest(path, requestOptions) {
  try {
    return await fetch(`https://backend.tallinn-learning.ee/${path}`, {
      ...requestOptions,
    })
  } catch (error) {
    return error
  }
}

//1 +
export const requestMethod = (method, setResult) => {
  setResult(method)
  sendRequest(`tasks/request_method`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(res => {
    return res
  })
}

//2 +
export const responseStatus = (status, setResult) => {
  sendRequest(`tasks/response_method`, {
    method: status === 202 ? "GET" : "POST",
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      setResult(res.status)
      return res
    })
}

//3 +
export const contentOfRequestBody = (option, setResult, inputValue) => {
  sendRequest(`tasks/content_of_request_body`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: option ? JSON.stringify({ "name": inputValue }) : JSON.stringify({})
  })
    .then(res => {
      setResult(option)
      return res?.json()
    })
}

//4 +
export const contentOfResponseBody = (option, setResult) => {
  sendRequest(`tasks/content_of_response_body`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(res => {
      setResult(option)
      return option ? res.json() : ""
    })
}

//5 +
export const setCookie = (days, setResult) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  setResult(days)
  const cookieValue = encodeURIComponent("your_cookie_value") + '; expires=' + expirationDate.toUTCString() + '; path=/';
  document.cookie = `devtools_session_id=${cookieValue}`;
}

//6 +
export const requestHeaders = (option, setResult) => {
  const headers = option ? {
    'Content-Type': 'application/json',
    "X-User-Id": "dfefvn2jk34h3vpbue9374h",
    Authorization: `Bearer ${localStorage.getItem('devToolsTrainingAppJWT')}`
  }
  :
  {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('devToolsTrainingAppJWT')}`

  }
  sendRequest(`tasks/request_headers`, {
    method: "POST",
    headers: headers,
  })
    .then(res => {
      setResult(option)
      return res
    })
}

//7 +
export const responseHeaders = (option, setResult) => {
  const headers = !option ? {
    'Content-Type': 'application/json',
    "X-Request-Id": "dfefvn2jk34h3vpbue9374h",
    Authorization: `Bearer ${localStorage.getItem('devToolsTrainingAppJWT')}`
  }
    :
    {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('devToolsTrainingAppJWT')}`,
    }
  sendRequest(`tasks/response_headers`, {
    method: "POST",
    headers: headers,
    body: option && JSON.stringify(option)
  })
    .then(res => {
      setResult(option)
      return res
    })
}

//8 +
export const queryParameters = (option, setResult) => {
  sendRequest(option ? "tasks/query_parameters?section=3" : `tasks/query_parameters`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('devToolsTrainingAppJWT')}`
    },
    body: !option ? JSON.stringify({ "section": 3 }) : JSON.stringify({})
  })
    .then(res => {
      return res
    }
    )
  setResult(option)
}

//9 +
export const pathParameters = (option, setResult) => {
  sendRequest(option ? "tasks/path_parameters/1" : "tasks/path_parameters?section=1", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('devToolsTrainingAppJWT')}`
    },
  })
    .then(res => {

      return option ? res.json() : ""
    }
    )
  setResult(option)
}

//10 +
export const localStorageTask = (fieldName, setResult, inputValue) => {
  localStorage.setItem(fieldName, inputValue)
  setResult(fieldName)
}

//11 +
export const sessionStorageTask = (fieldName, setResult, inputValue) => {
  if (fieldName !== "") {
    sessionStorage.setItem(fieldName, inputValue)
  }
  setResult(fieldName)
}

//12
export const cache = (status, setResult) => {

}

//12
// export const mobileLayout = (status, setResult) => {

// }

//13 +
export const cssColor = (color, setResult) => {
  setResult(color)
}

//14 +
export const cssFont = (font, setResult) => {
  setResult(font)
}

//15
// export const fieldValidation = (status, setResult) => {

// }
