import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
import { userSlice } from "./redux/reducers/userSlice";
import { isTokenValid } from "./utils/utils";
import { useAppDispatch } from "./redux/hooks/dispatch";
import HowDoesTheTrainerWork from "./components/Workspace/HowDoesTheTrainerWork";
const App = () => {
  const dispatch = useAppDispatch();
  const { setLogoutUser } = userSlice.actions

  useEffect(() => {
    const tokenIsValid = isTokenValid();
    if (!tokenIsValid) {
      dispatch(setLogoutUser())
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/tasks/:id" element={<MainPage />} />
      </Routes>
    </Router>
  );
};

export default App;
